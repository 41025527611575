@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap');
iframe#webpack-dev-server-client-overlay{display:none!important}
html{
  font-family: 'Lato','Roboto', sans-serif;
}
*{
  padding: 0;
  margin:0;
  box-sizing: border-box;
}
.react-datetime-picker__wrapper{
  padding: 6px;
  border-radius: 4px;
  width: 300px;
}
.SearchDiv {
  width: 100%;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
}
.SearchDiv input {
  width: 70%;
  padding: 7px 12px;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
}

.react-datetime-picker__wrapper{
  max-width: 300px;
  width:100%;
}